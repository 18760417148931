import React, { useState, useEffect } from "react";
import HomeDetails from "../components/home/HomeDetails";
import Error from "../components/Error";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

export default function HomeDetailsPage() {
  const [data, setData] = useState(null); // Change from array to null for single object data
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://amirrealtor-react-api.onrender.com/homes/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (isMounted) {
          setData(response.data); // Assuming response.data.home contains a single home object
          setLoading(false); // Set loading to false after data is fetched
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message); // Set error message if fetching fails
          setLoading(false);
        }
        console.error(err);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [id, refresh]); // Add 'id' to the dependency array to handle route changes

  if (loading) {
    return (
      <div className="bg-black flex items-center justify-center w-screen h-screen">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <HomeDetails data={data} />
    </div>
  );
}
