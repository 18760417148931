import React, { useState, useEffect } from "react";
import { BallTriangle } from "react-loader-spinner";

import { Link } from "react-router-dom";

const HomeItem = ({ data, error }) => {
  const houses = data
    ? data.filter((item) => item.address.toLowerCase().includes("dubai"))
    : [];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showError, setShowError] = useState(false);

  // Handle loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set a 60-second timeout to show the error message
    const timer = setTimeout(() => {
      if (isLoading && error) {
        setShowError(true);
      }
    }, 120000); // 60000 ms = 60 seconds

    // If data is received before timeout, stop loading and clear the timer
    if (data.length > 0) {
      setIsLoading(false);
      setShowError(false);
      clearTimeout(timer);
    }

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [isLoading]);

  const handleNext = () => {
    if (currentIndex + 3 < houses.length) {
      setCurrentIndex((prevIndex) => prevIndex + 3);
    }
  };

  const handlePrev = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3);
    }
  };
  return (
    <div className="w-full">
      <div className="flex justify-end gap-3 my-3">
        <Link to={`/homes`}>
          <small className="text-amir-300 text-sm">Sell all</small>
        </Link>
      </div>
      <div className="w-full">
        <div className="w-full">
          {" "}
          {showError ? (
            <p className="text-red-600 font-semibold">
              Error getting property information
            </p>
          ) : null}
          {houses.length === 0 && !data ? (
            <div className="flex items-center justify-between text-red-500">
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#FF5F01"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {houses.slice(currentIndex, currentIndex + 3).map((card) => (
                <div
                  key={card?._id}
                  className="w-full bg-white rounded-lg shadow-md overflow-hidden"
                >
                  <img
                    className="w-full h-60 object-cover rounded-lg"
                    src={
                      card?.images?.length > 0
                        ? card?.images[0]?.url
                        : "/assets/img/bg-hero.webp"
                    }
                    alt={card?.name}
                    height={229}
                    width={353}
                  />
                  <div className="p-4">
                    <h2 className="text-base font-bold text-amir-500">
                      {card?.name}
                    </h2>

                    <div className="mt-4 flex justify-between items-center">
                      <span className="text-gray-800 font-bold mr-6">
                        {card?.priceToBuy} AED
                      </span>
                      <Link to={`/homes/${card?._id}`}>
                        <button className="px-4 py-2 bg-amir-300 text-white rounded-md">
                          View property
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeItem;
