import React, { useState, useEffect } from "react";

function CarouselDetails({
  children: slides,
  autoSlide = false,
  autoSlideInterval = 3000,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () =>
    setCurrentSlide((currentSlide) =>
      currentSlide === slides.length - 1 ? 0 : currentSlide + 1
    );

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  });

  return (
    <div className="w-full relative overflow-hidden h-fit -z-10">
      <div
        className="flex transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides}
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-40"></div>

      <div className="absolute top-3/4 right-0 left-0">
        <div className="mx-auto flex items-center justify-center gap-2">
          {slides.map((_, i) => (
            <div
              key={i}
              className={`
                transition-all w-3 h-3 bg-amir-100 rounded-full ${
                  currentSlide === i ? "w-9 h-2" : "bg-opacity-50 bg-white"
                }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CarouselDetails;
