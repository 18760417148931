import Header from "./Header";
import { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import PropertiesCard from "./PropertiesCard";
import Footer from "./Footer";

import HomeItem from "./HomeItem";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const images = [
  "/assets/img/bg-hero.webp",
  "/assets/img/bg-hero2.webp",
  "/assets/img/bg-hero3.webp",
  "/assets/img/bg-hero4.webp",
  "/assets/img/bg-hero5.webp",
];

const Homepage = ({ data, error }) => {
  const navigate = useNavigate();
  console.log(data);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [address, setAddress] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(interval); // Clear interval on unmount
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Navigate to search results page with a general search term
    navigate(`/search-results?search=${encodeURIComponent(address)}`);
  };
  return (
    <div className="relative top-0">
      <Header />
      <div className="relative w-full h-screen overflow-hidden">
        {/* Carousel Images */}
        <div className="absolute inset-0 -z-40">
          {images.map((image, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-all duration-1000 ease-in-out ${
                currentIndex === index
                  ? "opacity-100 scale-100"
                  : "opacity-0 scale-105"
              }`}
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          ))}
        </div>

        {/* Dark overlay for text visibility */}
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

        {/* Static Text */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white w-">
          <h1 className=" text-2xl/10 tracking-wider lg:text-4xl/[72px] font-bold">
            <span className="font-semibold">AmirRealtors!</span>
            <br />
            Your Gateway to Premier <br />
            Properties
          </h1>
        </div>
      </div>
      {/* ---- Search component ---- */}
      <div>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center items-center px-4 lg:px-0 py-2 ">
            <div className="flex  w-full md:w-10/12 lg:w-8/12  rounded-lg border-2 border-gray-500 pr-1 py-1">
              <input
                type="search"
                placeholder="Enter your search keywords"
                className="text-gray-600 w-full border-none bg-transparent outline-none focus:outline-none focus:ring-0 focus:outline-0 mb-2  pl-3"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <button
                type="submit"
                className="bg-amir-300 text-sm font-bold text-white py-1 text-center rounded-md min-w-20 leading-3"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      {/*---- For sale ----*/}
      <div className="my-4 lg:w-8/12 py-4 mx-auto text-justify px-4 lg:px-0">
        <p className="text-amir-500 my-4">For Sale</p>
        <h3 className="text-amir-400 font-bold text-xl lg:text-3xl my-4">
          Find Your Dream Home with Our Exclusive Listings
        </h3>
        <div className="w-10/12 lg:hidden py-2">
          <img
            src="/assets/img/frame1.png"
            alt="client and homes"
            style={{ objectFit: "contain" }}
          />
        </div>
        <p className="text-base/[1.75rem] text-amir-200">
          Explore our collection of properties for sale or rent across the UAE.
          Whether you're looking for a modern apartment in the city, a luxury
          villa by the beach, or a spacious family home in a quiet neighborhood,
          we have a wide range of listings to suit every lifestyle and budget.
          Our expert agents are here to guide you through the buying process,
          ensuring you make the right investment in your future.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 my-4">
          <div className="w-10/12 hidden lg:block">
            <img
              src="/assets/img/frame1.png"
              alt="client and homes"
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="relative hidden lg:block">
            <h4 className="text-amir-400 font-bold text-lg my-4">
              Why Buy with Us?
            </h4>
            <ul className="list-disc">
              <li className="my-8">Access to exclusive listings</li>
              <li className="my-8">Expert advice and market insights</li>
              <li className="my-8">
                Tailored property search based on your preferences
              </li>
              <li className="my-8">
                Assistance with negotiations and paperwork
              </li>
            </ul>
            <div className=" lg:absolute bottom-0">
              <button className="bg-amir-300 text-white px-8 py-2 rounded-lg text-lg font-medium">
                Browse Properties for Sale
              </button>
            </div>
          </div>
          <div className=" lg:hidden flex justify-center items-center w-full">
            <Link to="/homes">
              <button className="bg-amir-800 text-black px-8 py-2 rounded-lg">
                Browse Properties for Sale
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ----- featured properties ---- */}
      <div className="bg-amir-600 py-8">
        <div className="w-full lg:w-8/12 mx-auto my-4 px-4 lg:px-0 overflow-x-hidden">
          <p className="text-amir-500 my-4">Featured Properties</p>
          <h3 className="text-amir-400 font-bold text-xl lg:text-3xl my-4">
            Explore Affordable Homes Across the UAE
          </h3>

          <PropertiesCard data={data} error={error} />
        </div>
      </div>

      {/* ----- featured home ---- */}
      <div className=" py-8">
        <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto my-4">
          <p className="text-amir-500 my-4">Featured Home</p>
          <h3 className="text-amir-400 font-bold text-3xl my-4">
            Affordable Homes in Dubai's Most Desirable Communities
          </h3>

          <HomeItem data={data} error={error} />
        </div>
      </div>
      {/* ----- Exclusive Homes Near the Palace ---- */}
      <div className=" py-8">
        <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto my-4">
          <p className="text-amir-500 my-4">Exclusive Homes Near the Palace</p>
          <h3 className="text-amir-400 font-bold text-3xl my-4">
            Where Luxury and Royalty Converge
          </h3>

          <p>
            Discover stunning homes located in close proximity to Dubai's most
            prestigious landmarks, offering unparalleled access to royal
            neighborhoods and world-class amenities.
          </p>
          <div>
            <img
              src="/assets/img/frame2.png"
              alt="houses near palace"
              className="rounded-lg object-contain w-full h-auto"
            />
          </div>

          <div className="w-full flex justify-end my-4">
            <button
              disabled
              className="bg-amir-100 text-white rounded-lg px-6 py-2"
            >
              Explore Homes Near the Palace
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
