import React from "react";
import { Link } from "react-router-dom";

const PropertyCardComp = ({ key, title, description, price, image, id }) => {
  return (
    <div
      key={key}
      className="w-full relative bg-white rounded-lg shadow-md overflow-hidden"
    >
      <img
        className="w-full h-60 object-cover rounded-lg"
        src={image}
        alt={title}
      />
      <div className="p-4 flex flex-col">
        <h2 className="text-lg font-bold text-gray-800">{title}</h2>

        <p className="text-gray-600 mt-2">{description}</p>
        <div className=" mt-4 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
          <span className="text-gray-800 font-bold mr-6 hidden lg:block">
            Starting from AED {price} million
          </span>
          <div className="lg:hidden text-left">
            <span className="text-gray-800 font-bold mr-6">
              STARTING FROM <br />{" "}
              <span className="text-amir-100"> AED {price} million</span>
            </span>
          </div>
          <div className="w-full my-4">
            <Link to={`/homes/${id}`}>
              <button className="px-4 py-2 bg-amir-400 lg:bg-amir-100 text-white rounded-md w-full lg:w-fit">
                View property
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCardComp;
