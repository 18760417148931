import React, { useEffect } from "react";
import "./mobileGallery.css";

import CarouselDetails from "./CarouselDetails";
const Images = (props) => {
  const { data, onClick } = props;

  const handleClickImage = (index) => {
    onClick(index);
  };

  console.log("console from images => ", data);

  return (
    <div className="overflow-hidden">
      <div className="hidden w-8/12 mx-auto py-4 cursor-pointer lg:flex flex-col gap-2">
        <div className="flex items-center gap-1 w-full">
          <div className="w-8/12 h-96" onClick={() => handleClickImage(0)}>
            <img
              src={data?.images[0]?.url || `/assets/img/amir.png`}
              className="w-full h-full object-cover"
              alt={data.name}
            />
          </div>
          <div className="w-4/12  h-96 flex flex-col gap-2">
            <div className="w-full h-full " onClick={() => handleClickImage(1)}>
              <img
                src={data?.images[1]?.url || `/assets/img/amir.png`}
                className="w-full h-44 object-contain"
                alt={data.name}
              />
            </div>
            <div className="w-full h-full " onClick={() => handleClickImage(2)}>
              <img
                src={data?.images[2]?.url || `/assets/img/amir.png`}
                className="w-full h-44 object-contain"
                alt={data.name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden w-8/12 mx-auto lg:grid grid-cols-5 gap-2 my-2">
        <div className="w-full h-40" onClick={() => handleClickImage(3)}>
          <img
            src={data?.images[3]?.url || `/assets/img/amir.png`}
            className="w-full h-full object-cover"
            alt={data.name}
          />
        </div>
        <div className="w-full h-40" onClick={() => handleClickImage(4)}>
          <img
            src={data?.images[4]?.url || `/assets/img/amir.png`}
            className="w-full h-full object-cover"
            alt={data.name}
          />
        </div>
        <div className="w-full h-40" onClick={() => handleClickImage(5)}>
          <img
            src={data?.images[5]?.url || `/assets/img/amir.png`}
            className="w-full h-full object-cover"
            alt={data.name}
          />
        </div>
        <div className="w-full h-40 " onClick={() => handleClickImage(6)}>
          <img
            src={data?.images[6]?.url || `/assets/img/amir.png`}
            className="w-full h-full object-cover"
            alt={data.name}
          />
        </div>
        <div
          className="w-full h-40 relative"
          onClick={() => handleClickImage(7)}
        >
          <img
            src={data?.images[7]?.url || `/assets/img/amir.png`}
            className="w-full h-full object-cover"
            alt={data.name}
          />
          {data.images.length > 8 ? (
            <div className="absolute top-16 left-8 bg-amir-400 opacity-40">
              <p className="text-white">+{data.images.length - 8} photos</p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="w-screen lg:hidden overflow-x-auto flex gap-2 hide-scrollbar px-4">
        {/* Image 1 */}
        <div
          className="min-w-[360px] max-w-[480px] h-auto cursor-pointer"
          onClick={() => handleClickImage(0)}
        >
          <img
            src={data?.images[0]?.url || `/assets/img/amir.png`}
            className="w-full h-auto block object-contain"
            alt={data.name}
          />
        </div>

        {/* Image 2 */}
        <div
          className="min-w-[360px] max-w-[480px] h-auto cursor-pointer"
          onClick={() => handleClickImage(1)}
        >
          <img
            src={data?.images[1]?.url || `/assets/img/amir.png`}
            className="w-full h-auto block object-contain"
            alt={data.name}
          />
        </div>

        {/* Image 3 */}
        <div
          className="min-w-[360px] max-w-[480px] h-auto relative cursor-pointer"
          onClick={() => handleClickImage(2)}
        >
          <img
            src={data?.images[2]?.url || `/assets/img/amir.png`}
            className="w-full h-auto block object-contain"
            alt={data.name}
          />
          {data.images.length > 8 ? (
            <div className="absolute top-24 left-40 bg-amir-400 opacity-40">
              <p className="text-white">+{data.images.length - 3} photos</p>
            </div>
          ) : null}
        </div>
      </div>

      {/* <div className="lg:hidden relative w-full">
        <CarouselDetails autoSlide={true} className="">
          {data.images.map((slide, index) => (
            <img
              key={index}
              src={slide.url}
              alt={`slide-${index + 1}`}
              className="w-full object-contain cursor-pointer"
              onClick={() => handleClickImage(index)}
            />
          ))}
        </CarouselDetails>
      </div> */}
    </div>
  );
};

export default Images;
