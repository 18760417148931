import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

import { GoDotFill } from "react-icons/go";
import { BallTriangle } from "react-loader-spinner";

function About() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    isMounted && setLoading(false);
    return () => {
      isMounted = false;
    };
  }, []);
  if (loading) {
    return (
      <div className="bg-black flex items-center justify-center w-screen h-screen">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="relative">
      <Header />
      <div className="relative w-full h-screen overflow-hidden">
        <div className="absolute inset-0 -z-40">
          <div
            className={`absolute inset-0 transition-all duration-1000 ease-in-out 
                
                  opacity-100 scale-100
                  
              }`}
            style={{
              backgroundImage: `url("/assets/img/bg-hero.webp")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

        {/* Dark overlay for text visibility */}
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

        {/* Static Text */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white w-8/12 lg:w-1/3 mx-auto">
          <div className="w-full mt-8">
            <h1 className="text-2xl lg:text-4xl/[72px] font-bold">
              Your Trusted Real Estate Partner in the UAE
            </h1>
          </div>
        </div>
      </div>
      {/*---- About Us section -----*/}
      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto py-4 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="w-full flex flex-col justify-between items-center">
          <div>
            <h3 className="font-bold text-2xl my-2">About Us</h3>
            <p className="text-justify text-amir-300 text-sm">
              At <span className="text-amir-100">AmirRealtors24</span>, we are
              dedicated to delivering exceptional real estate services across
              the UAE. Whether you’re buying your dream home, selling a
              property, looking for a rental, or investing in real estate, our
              team of experts is here to guide you through every step of the
              process. With years of experience and an in-depth understanding of
              the local market, we pride ourselves on providing personalized,
              professional, and results-driven solutions.
            </p>
          </div>

          <div className="hidden lg:block ">
            <h3 className="font-medium text-lg my-2">What We Do</h3>
            <p className="text-justify my-2 text-amir-700 text-sm">
              We offer a wide range of services, including property buying,
              selling, rentals, property management, commercial real estate, and
              investment advisory. Our mission is to connect people with the
              perfect property that meets their needs and lifestyle, while
              maximizing the value for sellers and investors.
            </p>
          </div>
        </div>
        <div className="w-full">
          <img
            src="/assets/img/tower.png"
            alt="tower house"
            className="w-full h-[400px]  object-cover"
          />
        </div>
        <div className="lg:hidden">
          <h3 className="font-medium text-lg my-2">What We Do</h3>
          <p className="text-justify my-2 text-amir-700 text-sm">
            We offer a wide range of services, including property buying,
            selling, rentals, property management, commercial real estate, and
            investment advisory. Our mission is to connect people with the
            perfect property that meets their needs and lifestyle, while
            maximizing the value for sellers and investors.
          </p>
        </div>
      </div>
      <div className="w-11/12 px-4 lg:px-0 lg:w-7/12 mx-auto my-4 bg-amir-800 p-6 rounded-lg">
        <h3 className="text-amir-100 my-4 text-lg text-center lg:text-left">
          Our Approach
        </h3>
        <p>
          At <strong>AmirRealtors24</strong>, we believe that every client
          deserves tailored services that go beyond the transaction. We focus on
          building lasting relationships through integrity, transparency, and
          trust. Our agents work closely with you to understand your goals and
          offer expert guidance throughout the process.
        </p>
      </div>
      <div className="w-full px-4 lg:px-0 my-4 lg:w-8/12 mx-auto lg:flex justify-between items-center">
        <div className="w-full lg:w-6/12">
          <h3 className="font-medium text-lg mb-2">Why Choose Us?</h3>
          <div className="lg:flex justify-between items-start mt-4 text-sm gap-8">
            <div>
              <div>
                <div className="flex gap-3 items-center font-medium">
                  <GoDotFill />
                  <h4>Local Expertise:</h4>
                </div>
                <p>
                  With a deep understanding of the UAE real estate market, we
                  offer unparalleled insights and access to exclusive listings.
                </p>
              </div>
              <div className="mt-4">
                <div className="flex gap-3 items-center font-medium">
                  <GoDotFill />
                  <h4>Comprehensive Solutions:</h4>
                </div>
                <p>
                  From residential properties to commercial spaces, we cover all
                  aspects of real estate to meet diverse needs.
                </p>
              </div>
            </div>
            <div>
              <div>
                <div className="flex gap-3 items-center font-medium">
                  <GoDotFill />
                  <h4>Client-Centered Service:</h4>
                </div>
                <p>
                  We prioritize your needs, ensuring a personalized approach and
                  a stress-free experience.
                </p>
              </div>
              <div className="mt-4">
                <div className="flex gap-3 items-center font-medium">
                  <GoDotFill />
                  <h4>Professional Team:</h4>
                </div>
                <p>
                  Our team consists of highly skilled and knowledgeable agents
                  who are committed to delivering exceptional service.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-8 lg:my-0 w-full lg:w-4/12">
          <img
            src="/assets/img/people.png"
            alt="business partners"
            className="w-full object-contain"
          />
        </div>
      </div>
      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto my-4">
        <h3 className="text-amir-100 text-lg my-2 font-medium">Our Vision</h3>
        <p className="text-sm">
          To be the leading real estate agency in the UAE, recognized for our
          commitment to client satisfaction and excellence in property services.
        </p>
      </div>
      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto my-4">
        <h3 className="text-amir-100 text-lg my-2 font-medium">Our Mission</h3>
        <p className="text-sm">
          To provide innovative real estate solutions, while fostering trust,
          transparency, and long-term client relationships.
        </p>
      </div>
      <div className="w-full px-4 lg:px-0 my-12 lg:w-8/12 mx-auto">
        <p className="text-sm">
          Whether you're looking for a home, an investment opportunity, or
          professional property management, AmirRealtors24 is here to make your
          real estate journey a success. Let's find your perfect property
          together.
          <br />
          <Link to="/service">
            <span className="text-amir-300">Explore Our Service</span>
          </Link>
          <br />
          <Link to="/contact">
            <span className="text-amir-300">Contact Us</span>
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default About;
