import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <div className="bg-amir-400 text-white py-4">
      <div className="bg-amir-300 mt-8 w-full mb-4 flex flex-col lg:flex-row lg:justify-center lg:items-center py-6 px-4 lg:px-0">
        <h3 className="lg:hidden font-medium my-4 text-white text-lg">
          Explore more properties
        </h3>
        <div className="w-full lg:w-8/12 grid  grid-cols-1 lg:grid-cols-4 gap-2 lg:gap-8">
          <Link href="#">
            <span className="">Property for sale in Abu Dhabi</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Ajman</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Al Ain</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Dubai</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Fujairah</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Ras Al Khaimah (RAK)</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Sharjah</span>
          </Link>
          <Link href="#">
            <span className="">Property for sale in Umm Al Quwain (UAQ)</span>
          </Link>
        </div>
      </div>
      <div className="my-4 flex justify-between items-center w-full lg:w-8/12 mx-auto">
        <div className="w-40 h-14">
          <Link to="/">
            <img
              src="/assets/img/FooterLogo.png"
              alt="Site logo"
              className="w-full"
            />
          </Link>
        </div>

        <div className="w-3/12 hidden lg:flex justify-between items-center">
          <Link href="#">
            <FaInstagram />
          </Link>
          <Link href="#">
            <FaFacebook />
          </Link>
          <Link href="#">
            <FaXTwitter />
          </Link>
          <Link href="#">
            <FaYoutube />
          </Link>
          <Link href="#">
            <FaPinterest />
          </Link>
        </div>
      </div>
      <hr className="my-4 w-11/12 lg:w-8/12 h-2 mx-auto" />
      <div className="w-full px-4 lg:px-0 lg:w-8/12 flex flex-col lg:flex-row lg:justify-between mx-auto">
        <div>
          <ul>
            <li className="my-2">
              <Link href="#">About Us</Link>
            </li>
            <li className="my-2">
              <Link href="#">Contact Us</Link>
            </li>
            <li className="my-2">
              <Link href="#">Feedback</Link>
            </li>
            <li className="my-2">
              <Link href="#">Sitemap</Link>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li className="my-2">
              <Link href="#">Terms & Conditions</Link>
            </li>
            <li className="my-2">
              <Link href="#">Privacy Policy</Link>
            </li>
            <li className="my-2">
              <Link href="#">Cookie Policy</Link>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li className="my-2">
              <Link href="#">Join Our Team</Link>
            </li>
            <li className="my-2">
              <Link href="#">Agent Zone</Link>
            </li>
            <li className="my-2">
              <Link href="#">Agency Products</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full lg:hidden flex gap-3 px-4 my-4 text-lg">
        <Link href="#">
          <FaInstagram />
        </Link>
        <Link href="#">
          <FaFacebook />
        </Link>
        <Link href="#">
          <FaXTwitter />
        </Link>
        <Link href="#">
          <FaYoutube />
        </Link>
        <Link href="#">
          <FaPinterest />
        </Link>
      </div>
      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto my-4">
        <span className="font-light">Copyright &copy; 2024 AmirRealtors24</span>
      </div>
    </div>
  );
}

export default Footer;
