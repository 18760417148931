import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

function Services() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component
    isMounted && setLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="bg-black flex items-center justify-center w-screen h-screen">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="relative">
      <Header />
      <div className="relative w-full h-screen overflow-hidden">
        <div className="absolute inset-0 -z-40">
          <div
            className={`absolute inset-0 transition-all duration-1000 ease-in-out 
                
                  opacity-100 scale-100
                  
              }`}
            style={{
              backgroundImage: `url("/assets/img/bg-hero.webp")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

        {/* Dark overlay for text visibility */}
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

        {/* Static Text */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white w-11/12 lg:w-1/3 mx-auto">
          <div className="w-full mt-8">
            <h1 className="text-2xl lg:text-4xl/[72px] font-bold">
              Expert Solutions for All Your Real Estate Needs
            </h1>
          </div>
        </div>
      </div>
      {/*---- Our service section -----*/}
      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto py-4">
        <h3 className="font-bold text-xl lg:text-3xl my-4">Our Services</h3>
        <p className="text-justify text-sm/7 lg:text-base/9 my-8">
          At <span className="text-amir-100">AmirRealtors24</span>, we offer a
          full suite of real estate services designed to make your property
          journey smooth and hassle-free. Whether you're buying, selling,
          renting, or investing, our team of experienced professionals is here
          to provide personalized guidance every step of the way.
        </p>
        {/* ---- Mobile screen start---- */}
        <div className="lg:hidden">
          <div className=" my-2">
            <h3 className="font-semibold my-2 text-amir-400 text-base">
              1. Property Buying
            </h3>
            <p className="text-amir-200 text-sm">
              Find the Perfect Home or Investment
            </p>
            <p className="my-2 text-xs/6">
              We help you navigate the UAE's competitive property market to find
              a home or investment that suits your needs and budget. From
              initial search to closing, we provide comprehensive support and
              expert advice to ensure a seamless buying experience.
            </p>
          </div>
          <div className="my-2">
            <h3 className="font-semibold my-2 text-amir-400 text-base">
              2. Property Selling
            </h3>
            <p className="text-amir-200 text-sm">
              Maximize the Value of Your Property
            </p>
            <p className="my-2 text-xs/6">
              Selling your property has never been easier. Our expert agents
              provide accurate market valuations, strategic marketing, and
              negotiation support to get you the best possible price for your
              home or investment property.
            </p>
            <Link to="/homes">
              <span className="text-amir-300 underline text-xs">
                Explore our listings
              </span>
            </Link>
          </div>
          <div className="my-2">
            <h3 className="font-semibold my-2 text-amir-400 text-base">
              3. Rental Services
            </h3>
            <p className="text-amir-200 text-sm">
              Find the ideal Rental or Rent Out Your Property
            </p>
            <p className="my-2 text-xs/6">
              Whether you're searching for a place to rent or looking to lease
              out your property, we handle all aspects of the rental process.
              For tenants, we find the right home to match your lifestyle. For
              landlords, we secure reliable tenants quickly and efficiently.
            </p>
            <Link to="/homes">
              <span className="text-amir-300 underline text-xs">
                Explore our listings
              </span>
            </Link>
          </div>
          <div className="my-2">
            <h3 className="font-semibold text-base my-2 text-amir-400">
              4. Property Management
            </h3>
            <p className="text-amir-200 text-sm">
              Stress-Free Management for Your Property
            </p>
            <p className="my-2 text-xs/6">
              Our property management services take the hassle out of being a
              landlord. We handle everything from tenant relations and rent
              collection to maintenance and repairs, ensuring your property is
              well-maintained and profitable.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-xs my-2">Contact Us</span>
            </Link>
          </div>
          <div className="my-2">
            <h3 className="font-semibold text-base my-2 text-amir-400">
              5. Real Estate Investment
            </h3>
            <p className="text-amir-200 text-sm">
              Unlock Profitable Investment Opportunities
            </p>
            <p className="my-2 text-xs/6">
              Looking to grow your wealth through real estate? Our investment
              advisory services offer in-depth market analysis and investment
              strategies tailored to your financial goals. We help you make
              informed decisions for long-term returns.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-xs my-2">Contact Us</span>
            </Link>
          </div>
          <div className="my-2">
            <h3 className="font-semibold text-base my-2 text-amir-400">
              6. Commercial Real Estate
            </h3>
            <p className="text-amir-200 text-sm">
              Find the Perfect Space for Your Business
            </p>
            <p className="my-2 text-xs/6">
              From offices to retail spaces, we provide comprehensive commercial
              real estate services to meet your business needs. Our team helps
              you find, negotiate, and secure the ideal commercial property to
              support your business growth.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-xs my-2">
                Speak with our advisory
              </span>
            </Link>
          </div>
          <div className="my-2">
            <h3 className="font-semibold text-base my-2 text-amir-400">
              7. Relocation Assistance
            </h3>
            <p className="text-amir-200 text-sm">Seamless Relocation Support</p>
            <p className="my-2 text-xs/6">
              Relocating to the UAE or moving within the country? Our relocation
              services ensure a smooth transition. We help you find a new home,
              complete the necessary paperwork, and settle into your new
              community with ease.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-xs my-2">Contact Us</span>
            </Link>
          </div>
        </div>
        {/* ---- Mobile screen end---- */}
        <div className=" hidden lg:flex  gap-4 flex-wrap mt-8 w-full relative">
          <div className="w-96 h-96 absolute top-6 right-0">
            <img
              src="/assets/img/biz.png"
              alt="business"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="w-4/12">
            <h3 className="font-semibold my-2 text-amir-400 text-lg">
              1. Property Buying
            </h3>
            <p className="text-amir-200">Find the Perfect Home or Investment</p>
            <p className="my-2 text-xs/6">
              We help you navigate the UAE's competitive property market to find
              a home or investment that suits your needs and budget. From
              initial search to closing, we provide comprehensive support and
              expert advice to ensure a seamless buying experience.
            </p>
          </div>
          <div className="w-4/12">
            <h3 className="font-semibold text-lg my-2 text-amir-400">
              4. Property Management
            </h3>
            <p className="text-amir-200">
              Stress-Free Management for Your Property
            </p>
            <p className="my-2 text-xs/6">
              Our property management services take the hassle out of being a
              landlord. We handle everything from tenant relations and rent
              collection to maintenance and repairs, ensuring your property is
              well-maintained and profitable.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-sm my-2">Contact Us</span>
            </Link>
          </div>

          <div className="w-4/12">
            <h3 className="font-semibold my-2 text-amir-400 text-lg">
              2. Property Selling
            </h3>
            <p className="text-amir-200">Maximize the Value of Your Property</p>
            <p className="my-2 text-xs/6">
              Selling your property has never been easier. Our expert agents
              provide accurate market valuations, strategic marketing, and
              negotiation support to get you the best possible price for your
              home or investment property.
            </p>
            <Link to="/explore">Explore our listings</Link>
          </div>
          <div className="w-4/12">
            <h3 className="font-semibold text-lg my-2 text-amir-400">
              5. Real Estate Investment
            </h3>
            <p className="text-amir-200">
              Unlock Profitable Investment Opportunities
            </p>
            <p className="my-2 text-xs/6">
              Looking to grow your wealth through real estate? Our investment
              advisory services offer in-depth market analysis and investment
              strategies tailored to your financial goals. We help you make
              informed decisions for long-term returns.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-sm my-2">Contact Us</span>
            </Link>
          </div>
          <div className="w-5/12">
            <h3 className="font-semibold my-2 text-amir-400 text-lg">
              3. Rental Services
            </h3>
            <p className="text-amir-200">
              Find the ideal Rental or Rent Out Your Property
            </p>
            <p className="my-2 text-xs/6">
              Whether you're searching for a place to rent or looking to lease
              out your property, we handle all aspects of the rental process.
              For tenants, we find the right home to match your lifestyle. For
              landlords, we secure reliable tenants quickly and efficiently.
            </p>
            <Link to="/explore">Explore our listings</Link>
          </div>
          <div className="w-5/12">
            <h3 className="font-semibold text-lg my-2 text-amir-400">
              6. Commercial Real Estate
            </h3>
            <p className="text-amir-200">
              Find the Perfect Space for Your Business
            </p>
            <p className="my-2 text-xs/6">
              From offices to retail spaces, we provide comprehensive commercial
              real estate services to meet your business needs. Our team helps
              you find, negotiate, and secure the ideal commercial property to
              support your business growth.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-sm my-2">
                Speak with our advisory
              </span>
            </Link>
          </div>
          <div className="w-6/12">
            <h3 className="font-semibold text-lg my-2 text-amir-400">
              7. Relocation Assistance
            </h3>
            <p className="text-amir-200">Seamless Relocation Support</p>
            <p className="my-2 text-xs/6">
              Relocating to the UAE or moving within the country? Our relocation
              services ensure a smooth transition. We help you find a new home,
              complete the necessary paperwork, and settle into your new
              community with ease.
            </p>
            <Link to="/contact">
              <span className="text-amir-300 text-sm my-2">Contact Us</span>
            </Link>
          </div>
        </div>
        <p className="text-xs my-4">
          Our goal is to provide exceptional service, no matter where you are in
          your real estate journey. Let us help you find the perfect property or
          make the most of your investment.{" "}
          <Link to="/contact">
            <span className="text-amir-300 text-xs my-2">Contact Us</span>
          </Link>{" "}
          today!
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
