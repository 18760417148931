import React, { useState, useEffect } from "react";

import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import { FaBars, FaTimes } from "react-icons/fa";
import { GrContact } from "react-icons/gr";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const links = [
    {
      id: 1,
      link: "/",
      text: "Home",
    },
    {
      id: 2,
      link: "/homes",
      text: "For sale",
    },

    {
      id: 3,
      link: "/rent",
      text: "For rent",
    },
    {
      id: 4,
      link: "/about",
      text: "About Us",
    },
    {
      id: 5,
      link: "/service",
      text: "Our Services",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    isMounted && setLoading(false);
    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="bg-black flex items-center justify-center w-screen h-screen">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="w-full h-fit pt-48  py-4 relative">
      {/* ----- Header section -----*/}
      {/* ----- Header section -----*/}

      <div className="w-full fixed z-50 h-fit flex justify-center">
        <header
          className="px-4 border-b-2 py-4 fixed top-0 w-full lg:w-8/12 transition-all duration-300 bg-white
          "
        >
          <nav className="flex flex-row-reverse justify-evenly lg:flex-row lg:justify-between items-center">
            <div className="">
              <Link to={links[0].link} smooth duration={500}>
                <img
                  src="/assets/img/LOGO.png"
                  alt="Amirrealtor Logo"
                  className="w-48 h-24 object-contain cursor-pointer hidden lg:block"
                />
              </Link>
              <Link to="/contact">
                <button className="bg-amir-100 px-4 py-2 rounded-lg text-white  lg:hidden">
                  <GrContact />
                </button>
              </Link>
            </div>

            <ul className="hidden md:flex">
              {links.map(({ id, link, text }) => (
                <li
                  key={id}
                  className="text-lg ml-4 cursor-pointer hover:scale-105 duration-200 text-amir-300
                  capitalize"
                >
                  <Link to={link}>{text}</Link>
                </li>
              ))}
            </ul>
            <Link to="/contact">
              <button className="bg-amir-100 px-4 py-2 rounded-lg text-white hidden lg:block">
                Contact Us
              </button>
            </Link>
            <div className="">
              <img
                src="/assets/img/LOGO.png"
                alt="Amirrealtor Logo"
                className="w-48 h-24 object-contain cursor-pointer block lg:hidden"
              />
            </div>
            <div
              onClick={() => setMenu(!menu)}
              className="cursor-pointer mr-2 md:hidden"
            >
              {menu ? (
                <FaTimes size={30} className="text-amir-100" />
              ) : (
                <FaBars size={30} className="text-amir-100" />
              )}
            </div>
          </nav>
          {menu && (
            <ul className="flex flex-col border-0 md:hidden absolute top-15 right-0 w-full shadow px-4 bg-white capitalize">
              {links.map(({ id, link, text }) => (
                <li
                  key={id}
                  className="text-center text-lg my-4 mr-4 cursor-pointer hover:scale-105 duration-200 text-gray-600"
                >
                  <Link onClick={() => setMenu(!menu)} to={link}>
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </header>
      </div>

      {/* ----- Header section ends -----*/}
      {/* ----- Header section ends -----*/}

      <div className="flex flex-col py-4 justify-center w-full px-2 lg:px-20 mx-auto h-full">
        <div className="pb-8 text-center">
          <h2 className="text-4xl font-bold inline">Contact</h2>
          <p className="py-6">Submit the form below to get in touch with me</p>
        </div>

        <div className=" flex justify-center items-center">
          <form
            action="https://getform.io/f/avreklya"
            method="POST"
            className=" flex flex-col w-full md:w-1/2"
          >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md focus:outline-gray-300"
              required="Enter your name"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md focus:outline-gray-300"
              required="Enter your email"
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="10"
              className="p-2 bg-transparent border-2 rounded-md focus:outline-gray-300"
              required="Please leave a message"
            ></textarea>

            <button className="text-white bg-gradient-to-b from-gray-800 to-black px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
