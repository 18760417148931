import React from "react";

function Error() {
  return (
    <div className="flex items-center justify-center">
      <div>
        <h2 className="text-red-400 text-lg font-medium my-4">
          An error occurred
        </h2>
        <button className="text-white bg-black px-4 py-2 rounded-lg w-fit h-fit my-8">
          Go Back
        </button>
      </div>
    </div>
  );
}

export default Error;
