import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { GrContact, GrLocation } from "react-icons/gr";
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from "../Footer";
import RealGallery from "../RealGallery";
import { FaBars, FaTimes } from "react-icons/fa";

function HomeRentDetailsComp({ data }) {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const links = [
    {
      id: 1,
      link: "/",
      text: "Home",
    },
    {
      id: 2,
      link: "/homes",
      text: "For sale",
    },

    {
      id: 3,
      link: "/rent",
      text: "For rent",
    },
    {
      id: 4,
      link: "/about",
      text: "About Us",
    },
    {
      id: 5,
      link: "/service",
      text: "Our Services",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="pt-32 relative w-full">
      {/* ----- Header section -----*/}

      <div className="w-full fixed top-0 z-50 h-fit flex justify-center">
        <header
          className="px-4 py-4 fixed top-0 w-full lg:w-8/12 transition-all duration-300 bg-white
          "
        >
          <nav className="flex flex-row-reverse justify-evenly lg:flex-row lg:justify-between items-center">
            <div className="">
              <Link to={links[0].link} smooth duration={500}>
                <img
                  src="/assets/img/LOGO.png"
                  alt="Amirrealtor Logo"
                  className="w-48 h-24 object-contain cursor-pointer hidden lg:block"
                />
              </Link>
              <Link to="/contact">
                <button className="bg-amir-100 px-4 py-2 rounded-lg text-white  lg:hidden">
                  <GrContact />
                </button>
              </Link>
            </div>

            <ul className="hidden md:flex">
              {links.map(({ id, link, text }) => (
                <li
                  key={id}
                  className="text-lg ml-4 cursor-pointer hover:scale-105 duration-200 text-amir-300
                  capitalize"
                >
                  <Link to={link}>{text}</Link>
                </li>
              ))}
            </ul>
            <Link to="/contact">
              <button className="bg-amir-100 px-4 py-2 rounded-lg text-white hidden lg:block">
                Contact Us
              </button>
            </Link>
            <div className="">
              <img
                src="/assets/img/LOGO.png"
                alt="Amirrealtor Logo"
                className="w-48 h-24 object-contain cursor-pointer block lg:hidden"
              />
            </div>
            <div
              onClick={() => setMenu(!menu)}
              className="cursor-pointer mr-2 md:hidden"
            >
              {menu ? (
                <FaTimes size={30} className="text-amir-100" />
              ) : (
                <FaBars size={30} className="text-amir-100" />
              )}
            </div>
          </nav>
          {menu && (
            <ul className="flex flex-col border-0 md:hidden absolute top-15 right-0 w-full shadow px-4 bg-white capitalize">
              {links.map(({ id, link, text }) => (
                <li
                  key={id}
                  className="text-center text-lg my-4 mr-4 cursor-pointer hover:scale-105 duration-200 text-gray-600"
                >
                  <Link onClick={() => setMenu(!menu)} to={link}>
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </header>
      </div>

      {/* ----- Header section ends -----*/}

      <div className="mt-4 w-full border-y-2 py-6">
        <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto flex justify-between items-center py-2">
          <div
            className="flex items-center gap-4 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </div>
          <Link
            to={`https://api.whatsapp.com/send?phone=19152058151&text=Hello,%20I%20will%20like%20to%20rent%20this%20${data.name}%20with%20id%20${data._id}%20`}
          >
            <button className="px-4 py-2 bg-amir-300 rounded-md text-white">
              Contact Agent
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto flex justify-between py-2 text-xxs lg:text-base font-medium">
        <a href="#gallery">
          <span>Gallery</span>
        </a>
        <a href="#apartment">
          <span>Apartment Info & Price</span>
        </a>
        <a href="#amenities">
          <span>Amenities</span>
        </a>
        <a href="#price">
          <span>Price Insights</span>
        </a>
      </div>
      <div id="gallery" className="my-2">
        <RealGallery slides={data} />
      </div>

      <div
        id="apartment"
        className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto py-4"
      >
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center w-full">
          <div className="w-full lg:w-4/12">
            <p className="lg:hidden my-2 text-amir-100 font-medium">
              {data.category}
            </p>
            <h2 className="font-bold text-3xl">AED {data.pricePerYearRent}</h2>
            <small>See upfront costs</small>
            <p>Own this from just {data.priceToBuy / 120} AED/month</p>
            <div className="my-2">
              <Link
                to={`https://api.whatsapp.com/send?phone=19152058151&text=Hello,%20I%20will%20like%20to%20rent%20this%20${data.name}%20with%20id%20${data._id}%20`}
              >
                <button className="px-4 py-2 bg-amir-300 rounded-md text-white">
                  Contact Agent
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full lg:w-6/12 text-center">
            <h3 className="my-4 font-semibold text-lg">Location</h3>
            <div
              className="py-2 px-4"
              style={{
                backgroundImage: `url("/assets/img/map-bg.png")`,
                backgroundSize: "cover",
              }}
            >
              <div className="text-xs flex items-center gap-2 mx-4 px-6 bg-amir-600 rounded-lg py-12">
                <GrLocation />
                <span>
                  {data?.address ||
                    "Parkside 2, EMAAR South, Dubai South (Dubai World Central), Dubai"}
                </span>
                <span className="text-amir-400">View on map</span>
                <FaArrowRightLong />
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <h4 className="my-4 font-semibold text-lg">Property details</h4>
          {data.description.map((content) => (
            <p key={content._id} className="text-sm my-2">
              {content.content}
            </p>
          ))}
        </div>
      </div>
      <div
        id="amenities"
        className="w-full px-4 lg:px-0 my-4 lg:w-8/12 mx-auto py-4"
      >
        <h2 className="font-semibold my-4 text-lg">Amenities</h2>
        <div className="w-full grid grid-cols-4 gap-8 text-xs">
          {data.amenities.map((amenity) => (
            <Link href="#">
              <span className="capitalize">{amenity.name}</span>
            </Link>
          ))}
        </div>
      </div>
      <div
        id="price"
        className="w-full px-4 lg:px-0 my-4 lg:w-8/12 mx-auto py-4"
      >
        <h2 className="font-semibold my-4 text-lg">Price Insight</h2>
        <div className="my-4">
          <p>
            {data.name} sold in{" "}
            {data.address || "Dubai South (Dubai World Central), Dubai"}{" "}
          </p>

          <div className="p-6 bg-amir-600 rounded-lg text-center">
            <p className="text-amir-200 my-4">
              <span className="font-bold">This property costs 6% less</span>{" "}
              than the average price of {data.name} in{" "}
              {data.area || "Dubai South (Dubai World Central), Dubai"}
            </p>
            <p className="mt-6 mb-2">Average Sale Price</p>
            <p className="font-semibold my-2">
              {data.priceToBuy + data.priceToBuy * 0.06} AED
            </p>
          </div>
          <p>
            The data displayed is based on average prices and sizes of all
            listings that were live on AmirRealtors24 Finder in{" "}
            {data.area || " Dubai South (Dubai World Central), Dubai"}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomeRentDetailsComp;
