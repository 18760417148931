import React, { useState, useEffect } from "react";
import PropertyCardComp from "./PropertyCardComp.jsx"; // Assuming Card is in the same folder
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BallTriangle } from "react-loader-spinner";

const PropertiesCard = ({ data, error }) => {
  const lands = data
    ? data.filter((item) => item.propertyType === "Apartment")
    : [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showError, setShowError] = useState(false);

  // Handle loading state
  const [isLoading, setIsLoading] = useState(true);

  // Media query to check if screen size is large (for handling one card at a time on small screens)
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    // Set a 60-second timeout to show the error message
    const timer = setTimeout(() => {
      if (isLoading && error) {
        setShowError(true);
      }
    }, 120000); // 60000 ms = 60 seconds

    // If data is received before timeout, stop loading and clear the timer
    if (data.length > 0) {
      setIsLoading(false);
      setShowError(false);
      clearTimeout(timer);
    }

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [isLoading]);

  // Set the screen size flag when the component mounts
  useEffect(() => {
    console.log("This is the screen size => ", window.innerWidth);
    const updateScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1200); // Adjust breakpoint if needed
    };

    // Update screen size on load and on window resize
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const itemsPerPage = isLargeScreen ? 2 : 1;

  const handleNext = () => {
    if (currentIndex + itemsPerPage < lands.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  return (
    <div className="w-full">
      <div className="flex justify-end gap-3 my-3">
        <button disabled={currentIndex === 0} onClick={handlePrev}>
          <IoIosArrowBack />
        </button>

        <button
          disabled={currentIndex + itemsPerPage >= lands.length}
          onClick={handleNext}
        >
          <IoIosArrowForward />
        </button>
      </div>
      <div className="w-full">
        {showError ? (
          <p className="text-red-600 font-semibold">
            Error getting property information
          </p>
        ) : null}
        <div className={`flex items-start justify-between gap-8`}>
          {isLoading && !data ? (
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#FF5F01"
              ariaLabel="ball-triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <>
              {lands.length === 0 ? (
                <div className="flex items-center justify-between text-red-500">
                  <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#FF5F01"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                lands
                  .slice(currentIndex, currentIndex + itemsPerPage)
                  .map((card) => (
                    <PropertyCardComp
                      key={card._id}
                      title={card.name}
                      description={card.description[0].content}
                      price={card.priceToBuy}
                      image={card.images[0]?.url}
                      id={card._id}
                    />
                  ))
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertiesCard;
