import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineCameraAlt } from "react-icons/md";
import { TbBed } from "react-icons/tb";
import { PiBathtubLight, PiRectangleDashedLight } from "react-icons/pi";
import LazyLoad from "./LazyLoad"; // Import the LazyLoad component

import { IoLocationOutline } from "react-icons/io5";

const AllHomesItem = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div>
      {data?.length > 0 ? (
        data.map((home) => (
          <LazyLoad key={home._id}>
            <div className="w-full max-w-4xl bg-white rounded-lg shadow flex flex-col lg:flex-row my-8">
              {/* Image section */}
              <div
                className="relative w-full lg:w-2/5 cursor-pointer"
                onClick={() => navigate(`/homes/${home._id}`)}
              >
                <img
                  className="object-cover w-full h-full rounded-l-lg"
                  src={home.images[0].url}
                  alt={home.name}
                />

                <div className="absolute top-4 left-4">
                  <div className="flex flex-col gap-4">
                    <span className="bg-orange-500 text-white text-xs font-semibold px-2 py-1 rounded-md">
                      Verified
                    </span>

                    <span className="bg-gray-500 text-white text-xs font-semibold px-2 py-1 rounded-md">
                      New
                    </span>
                  </div>
                </div>

                <div className="absolute bottom-4 right-4 lg:left-4 flex items-center space-x-2 text-white">
                  <span className="flex items-center justify-center bg-black bg-opacity-50 px-2 py-1 rounded-md">
                    <MdOutlineCameraAlt className="text-gray-300 font-bold mr-2" />
                    <span className="ml-1">{home.images.length || 0}</span>
                  </span>
                </div>
              </div>

              {/* Info section */}
              <div className="w-full p-4 lg:w-3/5 lg:p-6">
                <h3 className="text-xl font-bold text-orange-500">
                  {home.name}
                </h3>
                <h2 className="text-2xl font-semibold text-gray-800">
                  {home.priceToBuy} AED
                </h2>
                <p className="text-sm text-gray-600 mt-3">
                  {home.description[0].content}
                </p>

                <div className="flex items-center gap-4 mt-4 text-gray-700 flex-wrap">
                  <div className="flex items-center">
                    <TbBed className="w-6 h-6" />
                    <span className="ml-2">{home.bedrooms} Bedrooms</span>
                  </div>
                  <div className="flex items-center">
                    <PiBathtubLight className="w-6 h-6" />
                    <span className="ml-2">{home.bathroom} Bathrooms</span>
                  </div>
                  <div className="flex items-center">
                    <PiRectangleDashedLight className="w-6 h-6" />
                    <span className="ml-2">{home.size}</span>
                  </div>
                </div>

                <div className="mt-2 text-gray-500 flex items-center gap-4">
                  <IoLocationOutline /> {home.address}
                </div>

                <div className="mt-4 flex gap-4 flex-wrap">
                  <Link
                    to={`https://api.whatsapp.com/send?phone=19152058151&text=Hello,%20I%20will%20like%20to%20buy%20this%20${home.name}%20with%20id%20${home._id}%20`}
                  >
                    <button className="px-4 py-2 bg-gray-300 rounded-md">
                      Buy
                    </button>
                  </Link>
                  <a href="mailto:amirrealtors24@gmail.com?subject=Support%20Request">
                    <button className="px-4 py-2 bg-gray-300 rounded-md">
                      Email
                    </button>
                  </a>

                  <Link to="https://api.whatsapp.com/send?phone=19152058151&text=Hello,%20I%20am%20a%20visitor%20from%20your%20website,%20and%20I%20will%20like%20to%20inquire...">
                    <button className="px-4 py-2 bg-gray-300 rounded-md">
                      WhatsApp
                    </button>
                  </Link>

                  <Link to={`/homes/${home._id}`}>
                    <button className="px-4 py-2 bg-gray-300 rounded-md">
                      View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </LazyLoad>
        ))
      ) : (
        <p>No homes available</p>
      )}
    </div>
  );
};

export default AllHomesItem;
