import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import PropertyCard from "../components/home/PropertyCard";
import Footer from "../components/Footer";
import { BallTriangle } from "react-loader-spinner";

const SearchResults = () => {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Extract search term from the URL
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("search");

  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component
    const controller = new AbortController();
    const fetchHomes = async () => {
      try {
        // Make API call with search term and pagination
        const response = await fetch(
          `https://amirrealtor-react-api.onrender.com/homes/search?page=${currentPage}&limit=10&search=${encodeURIComponent(
            searchTerm
          )}`
        );
        const data = await response.json();

        // Set the properties and pagination data
        if (isMounted) {
          setProperties(data.homes);
          setTotalPages(data.totalPages);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false);
        }
        console.error("Error fetching properties:", error);
      }
    };

    fetchHomes();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [searchTerm, currentPage]); // Refetch when searchTerm or page changes

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return (
      <div className="bg-black flex items-center justify-center w-screen h-screen">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className="w-full lg:w-8/12 mx-auto px-4 lg:px-0">
        <h1 className="text-center font-bold text-lg my-4">
          Search Results for:{" "}
          <span className="text-amir-100">{searchTerm}</span>
        </h1>
        <div className="w-full flex justify-between items-center">
          <button
            className="w-fit px-3 py-1 bg-amir-300 text-white rounded-lg"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <Link to="/">
            <button className="bg-amir-100 text-white px-3 py-1 rounded-lg">
              Return to Homepage
            </button>
          </Link>
        </div>
        <div className="property-list w-full flex flex-col justify-center items-center gap-8">
          {properties.length > 0 ? (
            properties.map((property) => (
              <PropertyCard key={property.id} property={property} />
            ))
          ) : (
            <p className="text-red-500 font-medium my-8">
              Oops! No properties found
            </p>
          )}
        </div>
      </div>
      {/* Pagination Controls */}
      <div className="w-full lg:w-8/12 mx-auto my-4 px-4 lg:px-0 flex justify-between items-center">
        <div className="pagination w-full flex gap-3 items-center">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className="w-4/12 px-2">
          <p className="font-bold">
            Page {currentPage} of {totalPages}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchResults;
