import React, { useState, useEffect } from "react";

import Error from "../components/Error";
import axios from "axios";
import AllHomesItem from "../components/home/AllHomesItem";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { GrContact } from "react-icons/gr";
import { BallTriangle } from "react-loader-spinner";

import AllHomesRentItems from "../components/home/AllHomesRentItems";
import Footer from "../components/Footer";

export default function HomeRent() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const [menu, setMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Shuffle function (Fisher-Yates algorithm)
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };

  // Function to filter and shuffle the data
  const filterAndShuffleHomes = (homes) => {
    // Filter homes with 'buy' or 'Buy' in the 'market' field
    const filteredHomes = homes.filter((home) =>
      home.market.toLowerCase().includes("rent")
    );

    // Shuffle the filtered data
    return shuffleArray(filteredHomes);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Navigate to search results page with a general search term
    navigate(`/search-results?search=${encodeURIComponent(address)}`);
  };
  const links = [
    {
      id: 1,
      link: "/",
      text: "Home",
    },
    {
      id: 2,
      link: "/homes",
      text: "For sale",
    },

    {
      id: 3,
      link: "/rent",
      text: "For rent",
    },
    {
      id: 4,
      link: "/about",
      text: "About Us",
    },
    {
      id: 5,
      link: "/service",
      text: "Our Services",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://amirrealtor-react-api.onrender.com/homes",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (isMounted) {
          const dataToShuffle = response.data.homes;
          const finalData = filterAndShuffleHomes(dataToShuffle);
          setData(finalData);
          setLoading(false); // Set loading to false after data is fetched
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message); // Set error message if fetching fails
          setLoading(false);
        }
        console.error(err);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refresh]); // Only run on component mount

  if (loading) {
    return (
      <div className="bg-black flex items-center justify-center w-screen h-screen">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  if (data?.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="pt-32 relative w-full">
      {/* ----- Header section -----*/}

      <div className="w-full fixed z-50 h-fit flex justify-center">
        <header
          className="px-4 border-b-2 py-4 fixed top-0 w-full lg:w-8/12 transition-all duration-300 bg-white
          "
        >
          <nav className="flex flex-row-reverse justify-evenly lg:flex-row lg:justify-between items-center">
            <div className="">
              <Link to={links[0].link} smooth duration={500}>
                <img
                  src="/assets/img/LOGO.png"
                  alt="Amirrealtor Logo"
                  className="w-48 h-24 object-contain cursor-pointer hidden lg:block"
                />
              </Link>
              <Link to="/contact">
                <button className="bg-amir-100 px-4 py-2 rounded-lg text-white  lg:hidden">
                  <GrContact />
                </button>
              </Link>
            </div>

            <ul className="hidden md:flex">
              {links.map(({ id, link, text }) => (
                <li
                  key={id}
                  className="text-lg ml-4 cursor-pointer hover:scale-105 duration-200 text-amir-300
                  capitalize"
                >
                  <Link to={link}>{text}</Link>
                </li>
              ))}
            </ul>
            <Link to="/contact">
              <button className="bg-amir-100 px-4 py-2 rounded-lg text-white hidden lg:block">
                Contact Us
              </button>
            </Link>
            <div className="">
              <img
                src="/assets/img/LOGO.png"
                alt="Amirrealtor Logo"
                className="w-48 h-24 object-contain cursor-pointer block lg:hidden"
              />
            </div>
            <div
              onClick={() => setMenu(!menu)}
              className="cursor-pointer mr-2 md:hidden"
            >
              {menu ? (
                <FaTimes size={30} className="text-amir-100" />
              ) : (
                <FaBars size={30} className="text-amir-100" />
              )}
            </div>
          </nav>
          {menu && (
            <ul className="flex flex-col border-0 md:hidden absolute top-15 right-0 w-full shadow px-4 bg-white capitalize">
              {links.map(({ id, link, text }) => (
                <li
                  key={id}
                  className="text-center text-lg my-4 mr-4 cursor-pointer hover:scale-105 duration-200 text-gray-600"
                >
                  <Link onClick={() => setMenu(!menu)} to={link}>
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </header>
      </div>

      {/* ----- Header section ends -----*/}

      <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto mt-4 py-4">
        {/* ---- Search component ---- */}
        <div>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center items-center px-4 lg:px-0 py-2 ">
              <div className="flex  w-full md:w-10/12 lg:w-8/12  rounded-lg border-2 border-gray-500 pr-1 py-1">
                <input
                  type="search"
                  placeholder="Enter your search keywords"
                  className="text-gray-600 w-full border-none bg-transparent outline-none focus:outline-none focus:ring-0 focus:outline-0 mb-2  pl-3"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <button
                  type="submit"
                  className="bg-amir-300 text-sm font-bold text-white py-1 text-center rounded-md min-w-20 leading-3"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <hr className="w-full h-2 my-4" />
        <h3 className="text-xl lg:text-3xl font-bold text-amir-300">
          Properties for Sale in the UAE
        </h3>
        <div className="w-full bg-amir-600 text-xxs grid grid-cols-4 gap-4 py-8 px-8 rounded-xl my-8">
          <p>Apartments (124332)</p>
          <p>Land (5008)</p>
          <p>Hotel Apartments (425)</p>
          <p>Full Floors (54)</p>
          <p>Half Floors (8)</p>
          <p>Villas (42635)</p>
          <p>Duplexes (1319)</p>
          <p>Whole Buildings (371)</p>
          <p>Bulk Sale Units (51)</p>
          <p>Townhouses (19910)</p>
          <p>Penthouses (1277)</p>
          <p>Compounds (108)</p>
          <p>Bungalows (30)</p>
        </div>
      </div>
      <div className="w-full   flex flex-col justify-center items-center px-4 lg:px-0">
        {data ? (
          <AllHomesRentItems data={data} />
        ) : (
          <button onClick={() => setRefresh(!refresh)}>Refresh</button>
        )}
      </div>
      <Footer />
    </div>
  );
}
